
html, body {
    background:none transparent;
}

.login-box{
    max-width: 500px;
    margin: 10% auto 0;
    padding: 40px;
    border: 1px solid #3f5438;
    border-radius: 10px;
}
.login-logo{
    display: block;
    margin: 0 auto 20px;
}

.nps-item{
    cursor: pointer;
    margin-right: 10px;
}

.nps-item.inactive{
    filter: brightness(1.5);
}

.input_days{
    border-top: 1px solid #79d3f0!important
}

.input_days label{
    display: inline-block!important;
    padding-left: 5px;
}

.input_days input{
    width: calc(100% - 120px)!important;
    display: inline-block!important;
    border: none!important;;
}

.embedH5p{
    border: none;
    width: 100%;
    height: 100%;
}

.sidebar .sidebar-wrapper{
    background: #3f5438;
}

#pluzbot_chat{
    display: none;
}

#pluzbot{
    /*position: fixed;
    z-index: 9999999;
    right: 1%;
    bottom: 7%;*/
}

.form_chat{
    width: 80%;
    margin: 20px auto 0;
}

#pluzbot.chat-enable{
    width: 99%;
    max-width: 100%;
}

#pluzbot.chat-enable #pluzbot_chat{
    display: block!important;
}

/*
#pluzbot_chat:hover,
#pluzbot:hover #pluzbot_chat{
    display: block!important;
}
*/

#pluzbot_open{
    width: 60px;
    height: auto;
    float: right;
    cursor: pointer;
}


#save_success{
    display: none;
}

.react-datetime-picker__inputGroup__input{
    height: auto!important;
}

.sortable ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.box{
    padding: 5px ;
    margin: 10px 0;
    border: 1px dotted #ccc;
}

.sortable ul li,
body > li{
    list-style: none;
    margin: 8px;
    padding: 10px;
    border: 1px solid #ccc;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    cursor: pointer;
}

body > li{
    background: #eee;
}

th i{
    font-weight: bold;
    font-size: 20px;
    margin-bottom: -5px;
    margin-right: 5px;
    cursor: pointer;
}

/* Pagination */
.btn_page{
    cursor: pointer;
    display: inline-block;
    border: 1px solid #ccc;
    padding: 2px 6px;
    margin: 2px;
}

.btn_page_active{
    font-weight: bold;
    background: #ccc;
}

/* CHAT */
.chat_loading{
    border: 1px solid #999;
    background: #dbe7ec;
    border-radius: 15px;
    padding: 10px 8px;
    margin: 10px;
    margin-left: 42px;
    width: 50px;
    text-align: center;
}

.chat{
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #4b87c8;
    -webkit-box-shadow: 0 8px 14px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(63, 63, 68, 0.1);
    box-shadow: 0 8px 14px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(63, 63, 68, 0.1);
}

.chat_header{
    color: #4b87c8;
    background-color: #fff;
    border: 1px solid #79d3f0;
    font-size: 20px;
    border-bottom: 2px solid #79d3f0;
    padding: 5px 18px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.chat_label{
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    background-color: #5cb85c;
    float: right;
}

.chat_body{
    /*height: 48vh;*/
    height: 80%;
    overflow-y: scroll;
}
@media (max-height: 615px) {
    .chat_body{
        height: 77%;
    }
}
@media (max-height: 550px) {
    .chat_body{
        height: 72%;
    }
}
@media (max-height: 499px) {
    .chat_body{
        height: 68%;
    }
}
@media (max-height: 420px) {
    .chat_body{
        height: 56%;
    }
}

.chat_message .btn-info{
    color: #fff;
    background: #3f5438;
    border-color: #3f5438;
    margin-top: 20px;
    margin-right: 5px;
    padding: 2px 5px;
}

.chat_message{
    padding: 10px;
    border: 1px solid #f1f1f1;
    margin-bottom: -1px;
}

.chat_message b{
    display: block;
    margin-left: 42px;
}

.chat_message p{
    display: block;
    margin-left: 42px;
    margin-bottom: 0;
    margin-top: 5px;
    color: #8a8686;
}

.chat_message a{
    font-weight: bold;
    color: #4b87c8!important;
}


.chat_answered_question{
    margin: 3px;
    background: #edf1f3;
    padding: 5px;
    border-left: 4px solid #3f5438;
    margin-bottom: 5px;
    border-radius: 2%;
}
.chat_response{
    background: #b5cad3;
}
.chat_response .chat_answer p{
    color: #000;
    font-weight: bold;
}
.chat_answer ul li{
    margin: 10px 0;
}

.chat_answer a{
    cursor: pointer;
}

.chat_answer a:hover{
    text-decoration: underline;
}

.chat_message_admin{
    background: #dbe7ec;
}

.chat_message_admin .chat_answer b{
    color: #3f5438;
}
.chat_message_admin p{
    color: #444;
}

.chat_answered_question p,
.chat_answered_question b{
    margin-left: 10px!important;
    margin-top: 0;
    font-style: italic;
    font-size: 13px;
}

.chat_thumb{
    float: left;
}

.chat_thumb, .chat_thumb img{
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.chat_footer{
    position: relative;
    /*
    background-color: #fafafa;
    border-top: 1px solid #eee;
    */
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    
}

.chat_footer input{
    display: block;
    width: 100%;
    margin-bottom: 5px;
    padding: 15px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    border: none;
    border-top: 1px solid #79d3f0;
    /*
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    */
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.chatSendMessage{
    cursor: pointer;
    z-index: 99999;
    position: absolute;
    right: 15px;
    top: 15px;
    color: #3f5438;
}

.react-confirm-alert-overlay{
    opacity: 1!important;
}

/* Calendar */
.calendar{
    margin-left: 10px;
    padding-top: 30px;
}
.calendar_header{
    font-weight: bold;
}
.day_week_in_calendar{
    float: left;
    width: 14%;
    text-align: center;
    border: 1px solid #ddd;
    overflow-x: hidden;
}
.item_calendar{
    width: 500px;
    text-align: left;
}
.item_calendar a{
    display: block;
    background: #eee;
    border: 1px solid #ccc;
    margin: 3px 0;
    -webkit-border-radius: 50px;
    -moz-border-radius: 5px;
}

.day_week_in_calendar > div{
    padding: 13px 1px;
    height: 160px;
}
.day_week_in_calendar .current{
    background: #f0f0ff;
}
.day_week_in_calendar .current .number_day{
    font-weight: bold;
}

.calendar_header .day_week_in_calendar{
    height: auto;
}
.number_day{
    border-bottom: 1px dotted #ddd;
    font-size: 12px;
}
.clear{
    clear: both;
}


.main-panel {
    width: 100%; 
    padding-left: 260px;
    float: left;
}

.clear{
    clear: both;
}

@media (max-width: 991px) {
    .main-panel {
        padding-left: 0;
    }
}

